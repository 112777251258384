import PropTypes from 'prop-types';
import styled, {
  createGlobalStyle,
} from 'styled-components/macro';
import React, {
  useRef,
  useEffect,
} from 'react';
import SelectAntd from 'antd/es/select';
import 'antd/es/select/style/css';
import {
  theme,
} from '../utilsClient/cssHelpers';
import {
  resetStyles,
  lineHeights,
  sizes,
  fontSizes,
  transitionStyles,
  activeStyles,
} from './styles/form-controls';

const ComponentContainer = styled.div`
  display: inline-block;
  ${props => !props.compact && 'width: 100%'};
`;

const Component = styled(SelectAntd)`
  &&& {
    &,
    .ant-select-arrow {
      color: ${theme('color.onSurface')};
    }

    .ant-select-selection-placeholder,
    &:not(.ant-select-multiple)
      .ant-select-selector
      .ant-select-selection-item {
      ${props => lineHeights[props.size]};
    }
    &.ant-select-multiple .ant-select-selector .ant-select-selection-item {
      border-radius: ${theme('border.2')};
      background-color: ${theme('color.underlay')};
      color: ${theme('color.onUnderlay')};
      /* TODO: Should be in sync with theme */
      border: none;
    }
    /* Useful for E2E tests where the selection item covers the actual <input /> element */
    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      pointer-events: none;
    }

    /* Double selector hack to overwrite Ant Design specificity */
    .ant-select-selector.ant-select-selector {
      ${resetStyles};

      background-color: ${theme('color.surface')};
      border-radius: ${theme('border.radius.2')};
      box-shadow: ${theme('boxShadow.base')};
      padding-right: ${theme('space.2')};
      padding-left: ${theme('space.2')};

      ${transitionStyles};

      /* Modifiers */
      ${props => sizes[props.size]};
    }

    .ant-select-selection-item {
      ${props => fontSizes[props.size]};
    }

    /* Makes select menu clear button always visible */
    .ant-select-clear {
      opacity: 1;
    }

    &.ant-select-disabled {
      .ant-select-selector {
        background-color: ${theme('color.disabled')};
      }

      .ant-select-selector,
      .ant-select-arrow {
        color: ${theme('color.onDisabled')};
      }
    }

    &:hover,
    &.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        ${activeStyles};
      }
    }
  }
`;

const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown {
    &&& {
      background-color: ${theme('color.surface')};
    }
  }

  .ant-select-item-option {
    &&& {
      color: ${theme('color.onSurface')};

      &-selected {
        color: ${theme('color.onSurface')};
      }

      &-selected,
      &-active,
      &:hover:not(.ant-select-item-option-disabled) {
        background-color: ${theme('color.hover')};
      }
    }
  }

  .ant-select-item-group,
  .ant-empty {
    && {
      color: ${theme('color.onSurface')};
    }
  }
`;

const StyledSelect = ({
  // eslint-disable-next-line react/prop-types
  showSearch,
  compact,
  size,
  ...rest
}) => {
  const ref = useRef(null);

  useEffect(() => {
    // Ant Design select comes with autocomplete="off" for the search input
    // but it doesn't seem to work in Chrome
    // adding a dummy value to autocomplete attr seems to fix the issue
    // as per https://stackoverflow.com/a/38961567
    if (showSearch) {
      const searchInput = ref.current.querySelector(
        '.ant-select-selection-search-input',
      );

      if (searchInput) {
        searchInput.setAttribute('autocomplete', 'off');
      }
    }
  }, [
    showSearch,
  ]);

  const handleGetPopupContainer = () => {
    const dialog = ref.current && ref.current.closest('dialog');

    if (dialog) {
      return dialog;
    }

    return document.body;
  };

  return (
    <ComponentContainer
      ref={ref}
      compact={compact}
    >
      <GlobalStyle />
      <Component
        showSearch={showSearch}
        getPopupContainer={handleGetPopupContainer}
        size={size}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </ComponentContainer>
  );
};

StyledSelect.propTypes = {
  showSearch: PropTypes.bool,
  compact: PropTypes.bool,
  size: PropTypes.oneOf([
    'small',
    'default',
    'large',
  ]),
};

StyledSelect.defaultProps = {
  showSearch: false,
  compact: false,
  size: 'default',
};

export default SelectAntd;

export {
  StyledSelect,
};
