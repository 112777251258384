import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';

const Component = styled.img`
  object-fit: contain;
  max-width: 100%;
`;

const Image = ({
  src,
  alt,
  height,
  width,
}) => {
  return (
    <Component
      src={src}
      alt={alt}
      height={height}
      width={width}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

Image.defaultProps = {
  height: null,
  width: null,
};

export default Image;
