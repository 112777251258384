import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import Cluster from '../primitives/Cluster';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import {
  sizes,
  activeStyles,
} from '../styles/form-controls';
import {
  reset,
  types,
  ghost as ghostStyles,
  loading as loadingStyles,
  disabledCommon as disabledCommonStyles,
  iconOnly as iconOnlyStyles,
  block as blockStyles,
} from './styles';
import {
  typesOptions,
  sizesOptions,
} from './helpers';

const Component = styled.button`
  ${reset};

  cursor: pointer;
  border-radius: 1.25rem;
  letter-spacing: 0.05em;
  padding: 0 ${theme('space.3')};
  max-width: ${theme('measure.1')};
  white-space: nowrap;

  ${props => props.isAnimated &&
    `
    transition: all ${theme('motion.duration.fast')} ${theme(
  'motion.easing.standard',
)};
  `};

  &:focus {
    ${activeStyles};
  }

  svg {
    height: 1em;
    width: 1em;
  }

  /* Modifiers */

  ${props => props.stylesType && types[props.stylesType]};
  ${props => props.size && sizes[props.size]};
  ${props => props.ghost && ghostStyles[props.stylesType]};
  ${props => props.disabled && disabledCommonStyles};
  ${props => props.loading && loadingStyles[props.stylesType]};
  ${props => props.loading && 'cursor: default;'};
  ${props => props.block && blockStyles};
  ${props => props.iconOnly && iconOnlyStyles};
`;

const CustomIcon = ({
  icon,
  loading,
}) => {
  if (loading) {
    return <LoadingOutlined />;
  }

  return icon;
};

/**
 * Primary UI component for user interaction.
 */
const Button = React.forwardRef(
  (
    {
      'data-testid': datatestid,
      // eslint-disable-next-line react/prop-types
      className,
      htmlType,
      type,
      size,
      // eslint-disable-next-line react/prop-types
      icon,
      loading,
      disabled,
      block,
      ghost,
      onClick,
      onMouseEnter,
      onMouseLeave,
      children,
      // TODO: It's used only in `Pagination` to remove buttons transistion. Does it make sense to have it just for one use case?
      isAnimated,
    },
    forwardedRef,
  ) => {
    const handleOnClick = !loading && !disabled ? onClick : () => {};

    return (
      <Component
        ref={forwardedRef}
        data-testid={datatestid}
        className={className}
        type={htmlType}
        stylesType={type}
        size={size}
        onClick={handleOnClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        iconOnly={icon && !children}
        loading={loading || undefined}
        disabled={disabled}
        block={block}
        ghost={ghost}
        isAnimated={isAnimated}
      >
        {icon && !children && (
        <CustomIcon
          icon={icon}
          loading={loading}
        />
        )}
        {(icon || loading) && children ? (
          <Cluster
            space={2}
            justify="center"
            wrap="nowrap"
          >
            <CustomIcon
              icon={icon}
              loading={loading}
            />
            <span>{children}</span>
          </Cluster>
        ) : (
          children
        )}
      </Component>
    );
  },
);

Button.propTypes = {
  'data-testid': PropTypes.string,
  htmlType: PropTypes.string,
  type: PropTypes.oneOf(typesOptions),
  size: PropTypes.oneOf(sizesOptions),
  icon: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * Should render full width
   */
  block: PropTypes.bool,
  /**
   * Should render without background
   */
  ghost: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.node,
  isAnimated: PropTypes.bool,
};

const noop = () => {};
Button.defaultProps = {
  'data-testid': null,
  htmlType: 'button',
  type: 'default',
  size: 'default',
  icon: null,
  loading: false,
  disabled: false,
  block: false,
  ghost: false,
  onClick: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  children: null,
  isAnimated: true,
};

export default Button;
