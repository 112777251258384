import moment from 'moment';
import isNaN from 'lodash/isNaN';
import {
  YEAR_MONTH_DAY,
  DATE_TIME_FORMAT,
} from '../constants/date';
import {
  zoneToUtcOffset,
} from './zone';

export function toYearMonthDay(date, utcOffset) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format(YEAR_MONTH_DAY);
}

export function yearMonthDayShift(yearMonthDay, days) {
  if (typeof yearMonthDay !== 'string' || yearMonthDay.length < 10) {
    return yearMonthDay;
  }
  const ts = new Date(`${yearMonthDay}T00:00:00Z`).getTime();
  if (isNaN(ts)) {
    return yearMonthDay;
  }
  return new Date(ts + days * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
}

export function isValidYearMonthDay(isoString) {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(isoString)) {
    return false;
  }
  return !isNaN(new Date(isoString).getTime());
}

export function getCurrentYearMonthDay(utcOffset) {
  return toYearMonthDay(new Date(), utcOffset);
}

export function toHoursMinutes(date, utcOffset) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format('HH:mm');
}

export function getCurrentHoursMinutes(utcOffset) {
  return toHoursMinutes(new Date(), utcOffset);
}

export function toDateTimeString(date, utcOffset) {
  const m = moment(date);
  if (utcOffset) {
    m.utcOffset(utcOffset);
  }
  return m.format(DATE_TIME_FORMAT);
}

export function getCurrentDateTimeString(utcOffset) {
  return toDateTimeString(new Date(), utcOffset);
}

/**
 * Checks if the given date string contains utcOffset information.
 * @param {String} isoString
 * @returns {Boolean}
 */
export function hasUtcOffset(isoString) {
  return (
    /Z$/.test(isoString) ||
    /\+\d\d:\d\d$/.test(isoString) ||
    /\+\d\d\d\d$/.test(isoString) ||
    /-\d\d:\d\d$/.test(isoString) ||
    /-\d\d\d\d$/.test(isoString)
  );
}

export function addDays(isoString, nDays, timezone) {
  let m;
  if (!hasUtcOffset(isoString)) {
    // NOTE: In this case it is easy, because timezone does not change the result. We can just assume timezone = UTC and we will be fine.
    m = moment.utc(isoString, moment.ISO_8601).add(nDays, 'days');
  } else if (!timezone) {
    // NOTE: If timezone is not specified we try to re-use the same utc offset.
    m = moment.parseZone(isoString).add(nDays, 'days');
  } else {
    const ts = moment
      .utc(isoString, moment.ISO_8601)
      .add(nDays, 'days')
      .toDate();
    const utcOffset = zoneToUtcOffset(timezone)(ts);
    m = moment(ts).utcOffset(utcOffset);
  }
  if (m.isValid()) {
    return m.format(YEAR_MONTH_DAY);
  }
  return null;
}
