import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  BRANDING_TYPE__ZEDOC,
  BRANDING_TYPE__ZESIGHT,
} from '../common/constants/branding';
import LogoCommon from '../common/components/Logo';

const Component = styled(LogoCommon)`
  height: 2rem;
  max-width: 33%;
  margin-right: auto;
  margin-left: auto;
`;

const Logo = ({
  branding,
}) => {
  return <Component branding={branding} />;
};

Logo.propTypes = {
  branding: PropTypes.oneOf([
    BRANDING_TYPE__ZEDOC,
    BRANDING_TYPE__ZESIGHT,
  ]),
};

Logo.defaultProps = {
  branding: null,
};

export default Logo;
