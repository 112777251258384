import forEach from 'lodash/forEach';
import {
  plugins,
} from './plugins/register';
import parentLogger from '../../logger';

const pluginsCache = {
  db: new WeakMap(),
  fhir: new WeakMap(),
};

class BaseModel {
  constructor(doc) {
    Object.assign(this, doc);
    Object.defineProperty(this, 'raw', {
      value: this.constructor.getRawDoc(doc),
    });
  }

  getTier() {
    return this.restrictedTo;
  }

  permissionGrantSuffices(grant) {
    if (!this.restrictedTo) {
      return true;
    }
    return !!grant && grant.tier >= this.restrictedTo;
  }

  getAllVariables(variablesDb) {
    const {
      scopeName,
    } = this.constructor;
    const variables = {};
    forEach(variablesDb, (variable, variableId) => {
      if (variable.scopeName === scopeName) {
        variables[variableId] = variable.getValue(this);
      }
    });
    return variables;
  }

  static get db() {
    if (!this.plugins.db) {
      throw new Error('BaseModel plugin "db" was not registered');
    }
    if (!pluginsCache.db.has(this)) {
      pluginsCache.db.set(this, this.plugins.db.create(this));
    }
    return pluginsCache.db.get(this);
  }

  static get fhir() {
    if (!this.plugins.fhir) {
      throw new Error('BaseModel plugin "fhir" was not registered');
    }
    if (!pluginsCache.fhir.has(this)) {
      pluginsCache.fhir.set(this, this.plugins.fhir.create(this));
    }
    return pluginsCache.fhir.get(this);
  }

  static getRawDoc(doc) {
    let rawDoc = doc;
    while (rawDoc instanceof BaseModel) {
      rawDoc = rawDoc.raw;
    }
    return rawDoc;
  }

  static get create() {
    return doc => new this(doc);
  }
}

BaseModel.store = 'ddp';
BaseModel.logger = parentLogger.create('models');
BaseModel.plugins = plugins;

export default BaseModel;
