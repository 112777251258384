import {
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__NUMBER_WITH_UNIT,
} from '../../../constants';
import Question from '../Question';

export default class QuestionNumber extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__NUMBER;
  }

  getExpectedValueType(fieldName) {
    switch (fieldName) {
      case 'value':
      case 'other': {
        if (this.getPrecision() === 0) {
          return {
            type: 'integer',
          };
        }
        return super.getExpectedValueType(fieldName);
      }
      default:
        return super.getExpectedValueType(fieldName);
    }
  }
}

QuestionNumber.expectedRawKeys = [
  'value',
  'other',
  'text1',
  'text2',
];

QuestionNumber.expectedValueType = {
  type: 'number',
};

QuestionNumber.editorSettings = {
  limits: false,
  choices: true,
  snippets: false,
  additional: true,
};

Question.types[QUESTION_TYPE__NUMBER] = QuestionNumber;
Question.types[QUESTION_TYPE__NUMBER_WITH_UNIT] = QuestionNumber;
